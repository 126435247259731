@font-face {
    font-family: 'IBM Plex Sans Arabic';
    src: local('IBM Plex Sans Arabic'),
        url('../assets/fonts/ArbFONTS-Ubuntu-Arabic_R.ttf') format('truetype');
}

* {
    font-family: 'IBM Plex Sans Arabic' !important;

}

:root {
    --toastify-color-light: #EEEEEE;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #2c8b2c;
    --toastify-color-warning: #B09C38;
    --toastify-color-error: #c50500;
}

/** Classes for the displayed toast **/
.Toastify__toast {
    box-shadow: none !important;
    border-radius: 0;
}

.loader {
    position: relative;
    width: 50px;
    height: 50px;

}

.loader .circle {
    position: absolute;
    width: 38px;
    height: 38px;
    opacity: 0;
    transform: rotate(225deg);
    animation-iteration-count: infinite;
    animation-name: orbit;
    animation-duration: 5.5s;
}

.loader .circle:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: #00000066;
    box-shadow: 0 0 9px rgba(255, 255, 255, .7);
}

.loader .circle:nth-child(2) {
    animation-delay: 240ms;
}

.loader .circle:nth-child(3) {
    animation-delay: 480ms;
}

.loader .circle:nth-child(4) {
    animation-delay: 720ms;
}

.loader .circle:nth-child(5) {
    animation-delay: 960ms;
}



@keyframes orbit {
    0% {
        transform: rotate(225deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }

    7% {
        transform: rotate(345deg);
        animation-timing-function: linear;
    }

    30% {
        transform: rotate(455deg);
        animation-timing-function: ease-in-out;
    }

    39% {
        transform: rotate(690deg);
        animation-timing-function: linear;
    }

    70% {
        transform: rotate(815deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }

    75% {
        transform: rotate(945deg);
        animation-timing-function: ease-out;
    }

    76% {
        transform: rotate(945deg);
        opacity: 0;
    }

    100% {
        transform: rotate(945deg);
        opacity: 0;
    }
}

.table.small * {
    font-size: 12px;
}

.table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.table th {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: right;
    background-color: #f7f8f9;
    color: #54585d;
    border: 1px solid #b8b8b8;
}

.table td {

    text-align: right;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fff;
    color: black;
    border: 1px solid #dedfdf;
}

.table tr.error td {

    background-color: #FFEBEE !important;
}